import React from "react"
import { StaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"
import StyledUL from "../components/styled-ul"

const Posts = () => (
  <StaticQuery
    query={graphql`
      query Posts {
        allWordpressPost {
          edges {
            node {
              title
              featured_media {
                localFile {
                  childImageSharp {
                    fluid(maxWidth:2800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              excerpt
              content
              slug
              date(formatString: "MMMM DD, YYYY")
              datetime: date(formatString: "YYYY-MM-DD hh:mm:ss")
            }
          }
        }
      }
    `}
    render={ ({allWordpressPost}) => {
      return (
        <Layout>
          <SEO title="Blog" />
          <Header />
          <StyledUL posts={allWordpressPost.edges} />
        </Layout>
    )}
    }
  />

)

export default Posts;
